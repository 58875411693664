<template>
  <div class="top-match-section">
    <a-card>
      <transition-group v-if="groupedMatches && groupedMatches.length > 0" tag="div" class="grid" type="transition" name="flip-grid">
      <div v-for="champ in groupedMatches" :key="champ.champName" class="champ-group mb-4">
        <div class="mb-2 champ_name text-gray-8 d-flex">
          <span class="mt-0">
            <img v-if="champ.matches[0].sport_id" width="18" height="18" :src="`https://api.olimp.work/storage/sports/${champ.matches[0].sport_id}.png`" />
          </span>
          <span class="ml-2" style="margin-top: 0.1rem;">{{ champ.sportName + '. ' + champ.champName }}</span>
        </div>
        <transition-group v-if="matches && matches.length > 0" tag="div" class="grid" type="transition" name="flip-grid">
          <a-card
            v-for="(match) in champ.matches"
            hoverable
            :key="match.id"
            :bordered="match.is_active === true"
            class="match-card"
            :class="match.is_active === false ? 'match-card-inactive' : ''"
            @click="editMatchModal(match)"
          >
            <div class="card_info" v-if="1 === 0">
              <a-tooltip :title="`Status: ${match.status.toUpperCase()} \n ${(match.is_active ? 'Active' : 'Inactive' )}`" :overlayStyle="{ whiteSpace: 'pre-line' }" placement="bottom">
                <a-icon type="info-circle" />
              </a-tooltip>
            </div>
            <div :class="match.is_active ? 'text-gray-6' : 'text-gray-8'">
              <div class="d-flex">
                <div class="match_date">
                  <span v-if="match.is_active === true" class="mr-1">
                    <a-icon v-if="match.status === 'live'" type="fire" theme="filled" class="text-danger" />
                    <a-icon v-if="match.status === 'line'" type="calendar" theme="filled" class="text-primary" />
                    <a-tooltip v-if="match.status === 'results' && match.tmp === true" title="Temporary unavailable" placement="bottom" >
                      <a-icon type="eye-invisible" theme="filled" />
                    </a-tooltip>
                  </span>
                  {{ $moment(match.starts_at).format('DD.MM.YYYY HH:mm') }}
                </div>
                <div class="ml-auto match_weight text-gray-5"><a-icon type="gold" /> {{ match.weight }}</div>
              </div>
              <div class="match_name ml-1">
                <div class="d-block">
                  &#x2022; {{ match.c1 }}
                </div>
                <div class="d-block mt-1">
                  &#x2022; {{ match.c2 }}
                </div>
              </div>
              <a-row class="match_odds" v-if="1 === 0 && match.odds && match.odds.length > 0">
                <a-col class="match_odd rounded" v-for="odd in match.odds" :key="odd.id" :span="8">
                  <div class="odd_header rounded-top text-gray-5 bg-gray-1">{{ odd.n }}</div>
                  <div class="odd_val bg-gray-2 rounded-bottom">{{ odd.v }}</div>
                </a-col>
              </a-row>
              <a-button v-if="match.logs.length > 0" type="text" class="match_logs" shape="circle" :size="size" v-on:click.stop="openLogModal(match)">
                <a-icon type="bars" />
              </a-button>
            </div>
          </a-card>
        </transition-group>
      </div>
      </transition-group>
    </a-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ref } from 'vue'

export default {
  name: 'matchSection',
  props: ['matchesData'],
  components: {
  },
  setup() {
    return {
      size: ref('large'),
    }
  },
  computed: {
    ...mapState(['user']),
    groupedMatches() {
      const groupBy = (array, key1, key2) => {
        return array.reduce((result = [], currentValue = []) => {
          (result[currentValue[key1] + ';' + currentValue[key2]] = result[currentValue[key1] + ';' + currentValue[key2]] || []).push(currentValue)
          return result
        }, {})
      }
      const grouped = groupBy(this.matches, 'sport_name', 'champ_name')
      return Object.keys(grouped).map(key => {
        const sportAndChamp = key.split(';')
        return { sportName: sportAndChamp[0], champName: sportAndChamp[1], matches: grouped[key] }
      })
    },
  },
  data() {
    return {
      accessModule: 'top',
      matches: this.matchesData,
      deletingLoading: false,
    }
  },
  methods: {
    openLogModal(obj) {
      this.$root.$emit('openLogModal', JSON.parse(JSON.stringify(obj)))
    },
    createMatchModal() {
      this.$emit('createMatchModal')
    },
    editMatchModal(obj) {
      this.$emit('editMatchModal', JSON.parse(JSON.stringify(obj)))
    },
  },
}
</script>
<style lang="scss" src="./style.module.scss" />
