<template>
    <a-form-model v-if="loading">
      <a-row class="text-right pb-3">
        <a-button @click="getDataSource()">
          <a-icon type="reload" /> Reload
        </a-button>
      </a-row>
      <div class="text-center w-100">
        <a-spin class="flex m-auto mt-2" size="large" />
      </div>
    </a-form-model>
    <a-form-model v-else>
      <a-row class="text-right pb-3">
        <a-button @click="getDataSource()">
          <a-icon type="reload" /> Reload
        </a-button>
      </a-row>
      <a-row>
        <a-col :span="24" class="pr-1">
          <a-table :dataSource="dataSource" :columns="columns" class="logTable" />
        </a-col>
      </a-row>
    </a-form-model>
</template>
<script>

import TopMatchesService from '@/services/api/apiTopMatchesService'

export default {
  name: 'logsModal',
  props: ['matchObj'],
  components: {},
  setup() {
    return {
      columns: [
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'date',
          width: '20%',
        },
        {
          title: 'Message',
          dataIndex: 'message',
          key: 'message',
        },
      ],
    }
  },
  data() {
    return {
      loading: true,
      dataSource: [],
    }
  },
  mounted() {
    this.getDataSource()
  },
  methods: {
    getDataSource() {
      this.loading = true
      this.dataSource = []
      return TopMatchesService.getLogs(this.matchObj.id).then((response) => {
        if (response.data.data) {
          response.data.data.map((log, index) => {
            this.dataSource.push({ date: this.$moment.unix(log.timestamp).format('DD.MM.YY HH:mm:ss'), message: log.message })
          })
        }
        this.loading = false
      }).catch(error => {
        this.$notification.error({
          message: 'Error while loading logs',
          description: error.message,
        })
        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss">
.logTable td {
  max-width: 500px;
  white-space: pre;
  overflow-x: auto;
}
</style>
